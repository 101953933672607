<template>
  <div class="NoFunds">
    <v-form @submit.prevent="save">
      <v-container>
        <v-row
          align="center"
          justify="center"
          class="mb-6 pb-2"
        >
          <v-col
            cols="12"
            sm="2"
            md="4"
            lg="4"
          >
            <h1
              class="text-center"
            >
              تعبئة رصيد
            </h1>
          </v-col>
        </v-row>
        <v-row
          align="center"
          justify="center"
        >
          <v-col
            cols="12"
            sm="3"
            md="3"
            lg="3"
          >
            <h3>
              1- صورة ايصال الدفع
            </h3>
          </v-col>
          <v-col
            cols="12"
            sm="3"
            md="4"
            lg="3"
          >
            <v-file-input
              v-model="form.image"
              label="اضغط لاختيار صورة"
              accept="image/*"
              :error-messages="errors['image']"
            />
          </v-col>
        </v-row>
        <v-row
          align="center"
          justify="center"
        >
          <v-col
            cols="12"
            sm="3"
            md="3"
            lg="3"
          >
            <h3>
              2-المبلغ المدفوع
            </h3>
          </v-col>
          <v-col
            cols="12"
            sm="3"
            md="4"
            lg="3"
          >
            <v-text-field
              v-model="form.amount"
              type="number"
              label="المبلغ المدفوع"
              :error-messages="errors['amount']"
            />
          </v-col>
        </v-row>
        <v-row
          align="center"
          justify="center"
        >
          <v-col
            cols="12"
            sm="2"
            md="3"
            lg="3"
          >
            <v-btn
              x-large
              type="submit"
              color="primary"
              block
            >
              تأكيد التعبئة
            </v-btn>
          </v-col>
        </v-row>
      </v-container>
    </v-form>
  </div>
</template>

<script>
export default {
  data: () => ({
    form: {
      image: null,
      amount: 0,
      claim_type: 'haram',
    },
    errors: [],
  }),

  methods: {
    save () {
      this.loading = true
      const data = new FormData()
      for (const name in this.form) {
        data.append(name, this.form[name])
      }

      this.axios.get('/sanctum/csrf-cookie').then(() => {
        this.axios.post('/claims', data).then((res) => {
          console.log(res.data)
          this.$router.push({ name: 'charge-confirm.approve' })
        }).catch((err) => {
          if (err.status === 422) {
            this.errors = err.data.errors
          }
        }).then(() => {
          this.loading = false
        })
      })
    },
  },
}
</script>
